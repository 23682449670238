<template>
<div class="main has-bottom">
  <van-nav-bar class="top-bar" title="礼包" left-arrow @click-left="onClickLeft"/>
  <div class="gift-head flex justify-between align-center">
    <div class="gift-title">{{ detail.giftbag_name }}</div>
    <van-button v-if="detail.novice" class="gift-button" @click="clickCopy(detail.novice)">复制</van-button>
    <van-button v-else class="gift-button" @click="onReceive(detail.id)">领取</van-button>
  </div>
  <div class="gift-block">
    <div class="block-title">有效期</div>
    <div class="block-content fs12">{{ date(detail.start_time, 'Y-m-d H:i:s') }}至{{ detail.end_time ? date(detail.end_time, 'Y-m-d H:i:s') : '长期有效' }}</div>
  </div>
  <div class="gift-block" v-if="detail.digest">
    <div class="block-title">礼包内容</div>
    <div class="block-content fs14">{{detail.digest}}</div>
  </div>
  <div class="gift-block" v-if="detail.desribe">
    <div class="block-title">使用说明</div>
    <div class="block-content fs14">{{detail.desribe}}</div>
  </div>
  <div class="gift-block" v-if="detail.notice">
    <div class="block-title">注意事项</div>
    <div class="block-content fs14">{{detail.notice}}</div>
  </div>
</div>
</template>

<script>
import {Button, NavBar,Toast} from "vant";
import "@/style/common.css"
import {ref} from "vue";
import {giftDetail, receiveGift} from "@/api/game";
import {useRoute} from "vue-router";
import {date} from "@/utils/function";
import useClipboard from "vue-clipboard3";

export default {
  name: "GiftDetail",
  components:{
    [NavBar.name]:NavBar,
    [Button.name]:Button,
  },
  setup(){
    const detail = ref([])
    const onClickLeft = () => history.back();
    const {params} = useRoute();
    //获取礼包
    giftDetail(params.gift_id).then(({data})=>{
      detail.value = data
    })
    //领取礼包
    const onReceive = (ids)=>{
      receiveGift(ids).then(({data})=>{
        Toast('领取成功')
        detail.value.novice = data.novice
      })
    }
    //一键复制
    const {toClipboard} = useClipboard()
    const clickCopy = async (number) =>{
      try{
        await toClipboard(number)
        Toast("复制成功")
      }catch(e){
        Toast("复制失败")
      }
    }
    return{
      detail,
      onClickLeft,
      onReceive,
      clickCopy,
      date,
    }
  }
}
</script>

<style scoped>
.top-bar::after{
  display: none;
}
.gift-head{
  padding:20px 16px;
  background: url("../../assets/images/personal/gift-bar.png") bottom center no-repeat;
  background-size: 100% auto;
}
.gift-title{
  font-size: 16px;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 22px;
}
.gift-button{
  width: 80px;
  height: 32px;
  background: #FF7056;
  border-radius: 16px;
  border:none;
  font-size: 14px;
  font-weight: 500;
  color: #FFFFFF;
  padding:0px;
}
.gift-block{
  padding:10px 16px;
  text-align: left;
}
.block-title{
  font-size: 14px;
  font-weight: 500;
  color: #1D2129;
  line-height: 22px;
}
.block-content{
  font-weight: 400;
  color: #4E5969;
  line-height: 22px;
}
</style>
